import request from 'app/http'

const getHomeTask = (params) => {
  return request.get('/task/report/classic')
}
const getTrackedOperating = (params) => {
  return request.get('/stats/globalview/operating_data')
}
const getTrackedList = (params) => {
  return request.get('/stats/threatview/threat_char/', { params })
}

export {
  getHomeTask,
  getTrackedList,
  getTrackedOperating
}
